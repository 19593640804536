import { required, email, min, max, regex } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import * as Sentry from '@sentry/vue';

extend("required", {
  ...required,
  message: "This field is required"
});

extend("max", {
  ...max,
  message: "This field must be {length} characters or less"
});

extend("min", {
  ...min,
  message: "This field must be {length} characters or more"
});

extend("email", {
  ...email,
  message: "This field must be a valid email"
});

extend("influxEmail", email => {
  if (email.toLowerCase() === 'test@test.com') {
      return true;
  }

  const options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      key: process.env.VUE_APP_INFLUX_KEY,
    })
  };

  return fetch(`https://influx.consumerco.com/api/validate/email`, options)
    .then(response => response.json())
    .then(data => {
      if (!data.validation.domain) {
        return `${data.domain} is not a valid domain`;
      } else if (data.integration.exists && !data.integration.ok) {
        return "This field must be a valid email";
      } else {
        return true;
      }
    })
    .catch(error => {
      Sentry.captureException(new Error(`Email validation error for email ${email}: ${error.message}`), {
        extra: {
          error: error,
          email: email
        }
      });

      return true;
    });
});

extend("regex", regex)

extend("valid_date", {
  validate(value) {
    if (typeof value !== 'string' || !value.trim()) {
      return false;
    }

    const parts = value.split('-');
    if (parts.length !== 3) {
      return false;
    }

    const [year, month, day] = parts.map(Number);
    const date = new Date(year, month - 1, day);
    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
  },
  message: 'Please enter a valid date'
});

