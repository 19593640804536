import Vue from 'vue';
import VueRouter from 'vue-router';
import PageError from '../views/PageError.vue';
import PageNotFound from '../views/PageNotFound.vue';
import 'whatwg-fetch';

Vue.use(VueRouter)

const defaultMeta = {
  logo: require('@/assets/logo-consumerco.png'),
  logoWidth: 70,
  favicon: '/favicon-cc.svg',
  footer: {
    terms: 'https://www.consumerco.com/terms/',
    privacyPolicy: 'https://www.consumerco.com/privacy-policy/',
    unsubscribe: 'https://www.consumerco.com/unsubscribe/',
    doNotSellMyInformation: 'https://www.consumerco.com/do-not-sell-my-information/',
    textingTerms: 'https://www.consumerco.com/text-terms/',
  },
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [],
});

/**
 * Constructs a filename based on the provided host.
 *
 * @param {string} host - The host to be used in the filename.
 * @returns {string} The constructed filename.
 */
function constructFilename(host) {
  return `${host}.js`;
}

async function loadRoutesAndMeta() {
  let routes = [];
  let meta = defaultMeta;
  const host = process.env.NODE_ENV === 'development'
    ? process.env.VUE_APP_ACTIVE_DOMAIN
    : window.location.hostname;

  const fileName = constructFilename(host);

  try {
    const configModule = await import(`./domains/${fileName}`);
    routes = configModule.default || [];
    meta = configModule.meta || defaultMeta;
  } catch (error) {
    console.error(`Failed to import configuration for domain: ${host}`, error);
  }

  const defaultRoutes = [
    {
      path: '/unexpected-error',
      name: 'Error',
      component: PageError,
      meta,
    },
    {
      path: '/404',
      name: 'Page Not Found',
      component: PageNotFound,
      meta,
    },
    {
      path: '*',
      redirect: '/404',
    },
  ];

  routes = [
    ...routes,
    ...defaultRoutes
  ];

  return routes;
}

router.onError(error => {
  console.error('Routing error:', error);
});

export const routerReady = loadRoutesAndMeta().then(loadedRoutes => {
  loadedRoutes.forEach(route => router.addRoute(route));
  return router;
});
