<template>
  <v-main id="page_error" class="mt-3 mb-6">
    <v-container text-center pt-8>
      <h1>Sorry!</h1>
      <v-subheader class="justify-center mb-8">
        An unexpected error has occurred. Please try again.
      </v-subheader>
      <v-btn x-large color="primary" class="text-uppercase white--text" @click="goBack">
        Try again
      </v-btn>
    </v-container>
  </v-main>
</template>
<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
