<script>
export default {
  methods: {
    /**
     * Simple mobile check by screen width.
     *
     */
    isMobile() {
      return screen ? screen.width <= 760 : false;
    },

    /**
     * Convert a string into a slug.
     *
     */
    slug(string = null, whitespace_separator = '-') {
      if (string === null) {
        string = (Math.random() + 1).toString(36).substring(7);
      }

      return string
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, whitespace_separator)
        .replace(/^-+|-+$/g, '');
    },
  }
}
</script>
