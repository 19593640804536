<template>
  <div :id="`route__${slug($route.name, '_')}`">
    <v-app :class="{ 'with-app-bar': $route.meta.logo !== null, 'with-disclaimer': $route.meta.aboutPage }" >
      <v-app-bar app color="white" height="87" elevation="3" v-if="$route.meta.logo">
        <div class="d-flex flex-grow-1 align-center justify-center">
          <v-img
            alt="Logo"
            class="logo shrink mr-2 ml-2"
            contain
            :src="$route.meta.logo"
            transition="scale-transition"
            :width="$route.meta.logoWidth"
          />
        </div>
      </v-app-bar>

      <router-view></router-view>

      <v-footer class="d-flex flex-column align-center justify-center" color="white">
        <div v-if="footerDisclaimerText" class="flex-row align-center justify-center">
          <p class="disclaimer-ssdi text-center" v-html="footerDisclaimerText"></p>
        </div>
        <ul class="footer-links d-flex flex-row flex-wrap mb-4 justify-center">
          <li v-for="(item, index) in $route.meta.footer" :key="index">
            <a class="flex-nowrap" :href="item.url" target="_blank">
              {{ item.title }}
            </a>
          </li>
        </ul>
      </v-footer>
    </v-app>
  </div>
</template>
<script>
import MixinHelpers from '@/MixinHelpers.vue';
import MixinFetchVariables from '@/MixinFetchVariables.vue';
export default {
  mixins: [
    MixinFetchVariables,
    MixinHelpers,
  ],
  computed: {
    footerDisclaimerText() {
      const disclaimerKey = this.$route.meta.footerDisclaimerKey;
      if (disclaimerKey && typeof this.influxVariables[disclaimerKey] === 'string') {
        return this.influxVariables[disclaimerKey];
      }

      return null;
    },
  },
  methods: {
    setFavicon(href) {
      let link = document.createElement('link');
      link.setAttribute('rel', 'icon');
      link.setAttribute('href', href);

      let head = document.querySelector('head');
      head.appendChild(link);
    },

    /**
     * Add Google Tag Manager with a custom container ID per domain.
     */
    addGTM() {
      let containerId = this.$route.meta.gtmContainerId;
      if (!containerId || document.querySelector(`script[src*="${containerId}"]`)) {
        return;
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });

      let script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${containerId}`;
      document.head.appendChild(script);
    },
    addHeaderDisclaimerBarScript() {
      if (!this.$route.meta.aboutPage) {
        return;
      }

      let script = document.getElementById('disclaimer-script');
      script = document.createElement('script');
      script.id = 'disclaimer-script';
      script.src = `https://influx.consumerco.com/tag/nxt-68asppa3ok50`;
      script.defer = true;
      script.setAttribute('data-about_page', this.$route.meta.aboutPage);
      document.head.appendChild(script);
    },
  },
  async created() {
    let variablesToFetch = [];
    const disclaimerKey = this.$route.meta.footerDisclaimerKey;

    if (disclaimerKey) {
      variablesToFetch.push(disclaimerKey);
    }

    await this.updateInfluxVariables(variablesToFetch);
  },
  mounted() {
    this.addGTM();
    this.addHeaderDisclaimerBarScript();

    if (this.$route.meta.favicon !== null) {
      this.setFavicon(this.$route.meta.favicon);
    }
  }
}
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@900&family=Nunito:wght@400;700&display=swap");

$body-font-family: "Nunito";
$title-font: "Nunito";

.v-app-bar.v-app-bar--fixed {
  z-index: 11;
}

.disclaimer-ssdi {
  @media (max-width: 640px) {
    font-size: 9px !important;
  }
}

.with-app-bar.with-disclaimer {
  header {
    @media (max-width: 652px) {
      margin-top: 60.118px!important;
    }
    margin-top: 39px!important; 
  }

  main {
      @media (max-width: 640px) {
      padding-top: 7em !important;
    }
    padding-top: 7em !important;
  }
}
.top-bar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;
}

.theme--light.v-image.logo {
  @media (max-width: 640px) {
    width: 140px !important; // 205px
  }
}

.theme--light.v-toolbar,
.v-toolbar__content {
  @media (max-width: 640px) {
    height: 69px !important;
  }
}

.with-app-bar {
  main {
    @media (max-width: 640px) {
      padding: 69px 0 0 !important;
    }
  }
}

.v-progress-circular:not(.loading-progress-circular) {
  @media (max-width: 640px) {
    height: 52px !important;
    width: 52px !important;
  }
}

.v-progress-circular__info {
  @media (max-width: 640px) {
    font-size: 15px !important;
  }
}

.footer-links {
  @media (max-width: 640px) {
    font-size: 10px !important;
  }
}

.v-application {
  font-family: $body-font-family, sans-serif !important;

  .title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }

  .theme--light.v-subheader {
    color: #a7a7a7;
    font-size: 20px;
    height: auto;
    padding: 0;
  }

  .theme--light.v-subheader {
    @media (max-width: 640px) {
      font-size: 18px !important;
    }
  }

  .container {
    max-width: 1100px; // 940px
  }

  .v-btn.v-btn--has-bg.normal {
    background-color: #DCF3FE;
    border-color: #7ED5F9;
  }

  .v-card__title.disability-card-title {
    background-color: #162f59;
    color: #fff;
    font-size: 23px;
    justify-content: center;
    line-height: 1.4;
    word-break: normal;

    @media (max-width: 640px) {
      font-size: 18px;
    }

    p {
      margin: 0;
      max-width: 560px;
    }
  }

  .v-card.select-birth-year-card {
    .v-card__title {
      color: #162f59;
      font-size: 22px;
      font-weight: 700 !important;
    }
  }
}

.birth-year-item {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 25px;
  justify-content: center;
  transition: all 0.1s ease-in-out;

  &:hover {
    color: #162f59;
    font-size: 30px;
    font-weight: 700;
  }
}

.main-progress-circular .v-progress-circular__info {
  color: #162f59;
  font-size: 22px;
  font-weight: 700;
}

.loading-progress-circular .v-progress-circular__info {
  color: #a7a7a7;
  font-size: 16px;
}

.v-btn.btn-qualify {
  text-decoration: underline;

  &.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
    background-color: #c91d28c0 !important;
    color: #ffffffc0 !important;
  }
}

h1 {
  color: #000;
  font-family: "Merriweather", serif;
  font-size: 40px;
  font-weight: 900;

  @media (max-width: 640px) {
    font-size: 22px;
  }
}

.theme--light.v-btn.v-size--x-large {
  @media (max-width: 640px) {
    font-size: 18px;
  }
}

.v-application {
  a {
    color: #162f59;
  }

  .v-btn {
    text-transform: none;
  }

  .theme--light.v-btn.v-size--x-large {
    border-radius: 13px;
    color: #545454;
    font-size: 20px;
    font-weight: 700;
    height: 67px;
  }

  .v-card__title {
    color: #162f59;
    font-weight: 700;
    font-size: 26px;
    word-break: normal;

    @media (max-width: 640px) {
      display: block;
      font-size: 19px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .disclaimer-text,
  .disclaimer-ssdi {
    color: rgba(0, 0,0, 0.87);
    line-height: 1.28;
    a {
      color: inherit;
    }
  }

  .disclaimer-text {
    font-size: 14px;
  }

  .disclaimer-ssdi {
    font-size: 13px;
  }
}

.wrap-xs .v-btn__content {
  @media (max-width: 640px) {
    flex-direction: column !important;
  }
}

.shrink-xs {
  @media (max-width: 640px) {
    font-size: 16px;
  }
}

#tcpa_disclosure {
  display: none;
}

.v-application ul.footer-links {
  padding: 0;
  list-style-type: none;
  font-size: 15px;

  li {
    display: inline-block;
    padding-right: 1.25em;

    @media (max-width: 640px) {
      display: block;
      text-align: center;
    }

    &:last-child {
      padding-right: 0;
    }

    a {
      color: #162f59;
      text-decoration: none;
    }
  }
}

.pixel {
  display: none;
}

#route__explainer {
  .theme--light.v-application {
    background-color: #eef1f4;
  }
}
</style>
