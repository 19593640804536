<script>
export default {
  data() {
    return {
      /**
       * Stores the fetched influx variables.
       *
       * @type {Object}
       */
      influxVariables: {},
    };
  },
  methods: {
   /**
     * Updates the influx variables with any new keys that need to be fetched.
     *
     * @param {Array<string>} newVariableKeys - The new keys to fetch and add to the influx variables.
     */
    async updateInfluxVariables(newVariableKeys) {
      const currentKeys = Object.keys(this.influxVariables);
      const uniqueNewKeys = newVariableKeys.filter(key => !currentKeys.includes(key));

      if (uniqueNewKeys.length > 0) {
        await this._fetchInfluxVariables(uniqueNewKeys);
      }
    },

    /**
     * Fetches and caches the influx variables based on the provided keys.
     *
     * @param {Array<string>} variableKeys - The keys of the variables to fetch.
     */
    async _fetchInfluxVariables(variableKeys) {
      const cacheKey = 'influxVariablesCache';
      let cachedData = this._getCachedData(cacheKey);

      const missingKeys = this._determineMissingKeys(variableKeys, cachedData);

      if (missingKeys.length > 0) {
        await this._fetchAndCacheMissingKeys(missingKeys, cacheKey, cachedData);
      } else {
        this.influxVariables = { ...this.influxVariables, ...cachedData };
      }
    },

    /**
     * Retrieves the cached influx variables data from sessionStorage.
     * Gracefully handles errors if sessionStorage access is denied.
     *
     * @param {string} cacheKey - The key used to store the cached data in sessionStorage.
     * @returns {Object} The cached data if available, otherwise an empty object.
     */
    _getCachedData(cacheKey) {
      try {
        const cached = sessionStorage.getItem(cacheKey);
        return cached ? JSON.parse(cached) : {};
      } catch (error) {
        console.warn('Failed to access sessionStorage:', error);

        return {};
      }
    },

    /**
     * Determines which of the requested variable keys are missing from the cached data.
     *
     * @param {Array<string>} variableKeys - The keys of the variables to check.
     * @param {Object} cachedData - The currently cached data.
     * @returns {Array<string>} The keys that are missing in the cached data.
     */
    _determineMissingKeys(variableKeys, cachedData) {
      return variableKeys.filter(key => !(key in cachedData));
    },

    /**
     * Fetches the missing keys from the API, attempts to update the cache, and merges the results with any existing data.
     * Includes error handling for sessionStorage access.
     *
     * @param {Array<string>} missingKeys - The keys that need to be fetched.
     * @param {string} cacheKey - The sessionStorage key where the cached data is stored.
     * @param {Object} cachedData - The currently cached data to merge with the new fetch results.
     */
    async _fetchAndCacheMissingKeys(missingKeys, cacheKey, cachedData) {
      const requestBody = {
        keys: missingKeys,
      };

      try {
        const data = await this._performApiFetch(requestBody);
        const updatedData = this._mergeData(cachedData, data);

        this.influxVariables = updatedData;
        try {
          sessionStorage.setItem(cacheKey, JSON.stringify(updatedData));
        } catch (error) {
          console.warn('Failed to update sessionStorage:', error);
        }
      } catch (error) {
        console.error('Failed to fetch missing keys:', error);
        if (Object.keys(cachedData).length) {
          this.influxVariables = cachedData;
        }
      }
    },

    /**
     * Performs the API fetch operation for the provided request body.
     *
     * @param {Object} requestBody - The request body containing the keys to fetch.
     * @returns {Promise<Object>} The fetched data from the API.
     * @throws {Error} Throws an error if the network response is not ok.
     */
    async _performApiFetch(requestBody) {
      const response = await fetch(`${this.$influxApiBaseUrl}/variables`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) throw new Error('Network response was not ok');
      let jsonResponse = await response.json();

      jsonResponse.forEach(item => {
        const mergedVariables = this._mergeVariables(
          item.fallback_values,
          this.$route.meta.formDisclaimerVariables
        );
        this.replaceDisclaimerVariables(mergedVariables, item);
      });

      return jsonResponse;
    },

    _mergeVariables(primary, fallback) {
      return { ...fallback, ...primary };
    },

    /**
     * Replaces variables in the body of an item with corresponding values from a source object.
     * 
     * @param {Object} sourceObject - The object containing the variable-value pairs.
     * @param {Object} item - The item whose body will be modified.
     */
    replaceDisclaimerVariables(sourceObject, item) {
      for (const [key, value] of Object.entries(sourceObject)) {
        const regex = new RegExp(`{{${this.toSnakeCase(key)}}}`, 'g');
        item.body = item.body.replace(regex, value);
      }
    },

    /**
     * Converts a string to snake case.
     *
     * @param {string} str - The string to convert.
     * @returns {string} The converted string in snake case.
     */
    toSnakeCase(str) {
      return str.replace(/([A-Z])/g, '_$1').toLowerCase();
    },

    /**
     * Merges the newly fetched data with the existing cached data.
     *
     * @param {Object} cachedData - The currently cached data.
     * @param {Array<Object>} newData - The new data fetched from the API.
     * @returns {Object} The merged data.
     */
    _mergeData(cachedData, newData) {
      return newData.reduce((acc, item) => {
        acc[item.key] = item.body;
        return acc;
      }, cachedData);
    },
  },
};
</script>
