import Vue from 'vue'
import App from './App.vue'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import VueTheMask from 'vue-the-mask'
import { routerReady } from './router';
import VueCookies from 'vue-cookies';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const allowTraceDomains = [
  'my.benefits.help',
  'path.applysectioneight.com',
  'path.approvedmodems.org',
  'path.benefitsapplication.com',
  'path.filedebtrelief.org',
  'path.filedisability.org',
  'path.fileunemployment.org',
  'path.hardshipstimulus.com',
  'path.homeowner.com',
  'path.offer.town',
  'path.sectioneightapplication.com',
  'path.thepennyhoarder.com',
  'path.webmonkey.com',
];

const disallowTraceDomains = [
  'c.statcounter.com',
  'campaign.',
  'tags.srv.stackadapt.com',
  'www.google-analytics.com',
];

// Set the API base URL globally
Vue.prototype.$influxApiBaseUrl = process.env.VUE_APP_INFLUX_API_URL
  || 'https://influx.consumerco.com/api';

Vue.config.productionTip = false

Vue.use(VueTheMask);
Vue.use(VueCookies);
Vue.$cookies.config('1d');

routerReady.then(router => {
  Sentry.init({
    Vue,
    attachProps: true,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    tracesSampleRate: 0.025,
    replaysSessionSampleRate: 0.0125,
    replaysOnErrorSampleRate: 0.75,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [...allowTraceDomains, /^\//],
        shouldCreateSpanForRequest: (url) => {
          disallowTraceDomains.forEach(item => {
            return !url.match('/' & item & '/');
          });
        }
      }),
      new Sentry.Replay()
    ],
  });

  new Vue({
    vuetify,
    router,
    render: h => h(App)
  }).$mount('#app')
}).catch(error => {
  console.error('Failed to initialize router:', error);
});
